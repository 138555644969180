<template>
  <div class="call-to-action mt-1">
    <SmartLinkElement
      v-if="model"
      :codename="contentTypes.call_to_action.elements.link_title.codename"
      :item-id="model?.system?.id"
    >
      <NuxtLink
        :class="ctaClass ?? contextClass ?? 'btn btn-primary text-white'"
        :target="targetName(model)"
        :to="ctaLink"
      >
        {{ model?.elements?.linkTitle?.value }}
      </NuxtLink>
    </SmartLinkElement>
  </div>
</template>

<script setup lang="ts">
import { contentTypes } from '~/models'
import type { CallToActionModel } from '~/models'

const pageStore = usePageStore()

const props = defineProps<{
  model: CallToActionModel
  customTarget?: string
  contextClass?: string
}>()

const ctaClass = ref<string | undefined>()
const ctaLink = ref<string>('')
if (props.model) {
  useCtaLink(props.model).then((url) => {
    if (url) ctaLink.value = url
  })
  const color = firstOrDefault(props.model.elements.color.value)?.codename
  switch (color) {
    case 'use_page_theme':
      ctaClass.value = `btn btn-manatt-black text-white`
      break
    default:
      if (color) {
        ctaClass.value = `btn btn-manatt-black text-white`
      }
      break
  }
}

function targetName(cta: CallToActionModel) {
  return props.customTarget ? props.customTarget : useCtaTarget(cta)
}
</script>
